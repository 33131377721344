import { ProjectsList } from '../types/ProjectsList';
import Projects from '../project-components/Projects';
import { useEffect } from 'react';

interface ProjectPageProps {
    projects: ProjectsList;
}

export default function ProjectsPage(props: ProjectPageProps) {
    useEffect(() => {
        document.title = "Projects | Portfolio";

    }, []);


    return (
        <div className = "projects">
            <div className = "row mt-5">
                <div className = "col-12">
                    <h1>Professional Projects</h1>
                </div>
            </div>
            <div className = "row p-2 justify-content-center">
                <Projects title = "Professional Projects" projects = {props.projects.professional}/>
            </div>
            <div className = "row mt-5">
                <div className = "col-12">
                    <h1>Personal Projects</h1>
                </div>
            </div>
            <div className = "row p-2 justify-content-center">
                <Projects title = "Personal Projects" projects = {props.projects.personal}/>
            </div>
        </div>
    );
}