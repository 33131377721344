import React from 'react';
import './css/NavBar.css';
import { useNavigate } from 'react-router-dom';

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg bg-white">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end pt-2 pe-5" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <button className="navbar-closer" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="bi bi-x-lg"></span>
                    </button>

                    <li className="nav-item p-2 active">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item p-2">
                        <a className="nav-link" href="/projects">Projects</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default NavBar;