import React from 'react';

interface SocialProps {
    social: string;
    url: string;
    icon: string;
    color: string;
}

function Social(props: SocialProps) {

    return (
        <div className="justify-content-center">
            <a href={props.url} className="btn btn-outline-light btn-social mx-1" style={{backgroundColor: props.color, width: "240px"}}>
                <i className={"bi " + props.icon}></i>
                &nbsp;&nbsp;
                <span className="ms-1">{props.social}</span>
            </a>
        </div>
    )
}    

export default Social;