import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/js/src/collapse.js";
import './App.css';

import NavBar from './header/NavBar';
import projectsData from './data/projects.json';
import { ProjectsList } from './types/ProjectsList';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import Footer from './Footer';

function App() {
  const data: ProjectsList = projectsData;

  return (
    <div className="App">
        <NavBar />
        <div className = "container-fluid body-container p-0 overflow-hidden">
          <div id = "content-holder" className = "pb-5">
            <BrowserRouter>
              <Routes>
                <Route index element={<Home data = {data}/>}/>
                <Route path="/projects" element={<ProjectsPage projects = {data}/>}/>
                <Route path="/projects/:projectTitle" element={<ProjectPage projects = {data}/>}/>
              </Routes>
            </BrowserRouter>
          </div>
          <Footer />
        </div>
    </div>
  );
}

export default App;
