import React from 'react';
import './css/SvgWave.css';

interface SvgWaveProps {
    className: string;
    color: string;
}

function SvgWave(props: SvgWaveProps) {
    return (
        <div className= {props.className + " p-0"}>
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill" 
                style={{fill: props.color}}></path>
            </svg>
        </div>
    )
}


export default SvgWave;