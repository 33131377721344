import React from 'react';
import './css/Bio.css';


function Bio() {
    return (
        <div id="bio" className="text-start">
            <h2>My name is <strong>Anthony Marcellino</strong>,</h2>
            <p>I am a software developer based in Nova Scotia with speciality in <strong>Java</strong> and <strong>Web Application Development.</strong></p>
            <p>I've been tinkering with programming since I was a teenager. I have 
            spent the majority of my free-time learning new skills, making websites, 
            managing servers and coding cool stuff!</p>
        </div>
    )
}

export default Bio;