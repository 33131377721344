import React from 'react';
import About from './About';
import SvgWave from './SvgWave';
import './css/Hero.css';

function Hero() {
    return (
        <div className = "container-fluid hero p-0">
          <div className = "row align-items-center w-100 h-100 hero-row d-flex justify-content-center">
            <div className = "col-md-4 hero-section">
                <About />
            </div>
            <div className = "col-md-4 hero-section">
              <div id="headshot">
                <img src="headshot.jpg" alt = "headshot" className = "img-fluid" />
              </div>
            </div>
            <SvgWave className='custom-shape-divider-bottom-1721735278' color='#f8f8f8' />
            <SvgWave className='custom-shape-divider-bottom-1721735277' color='#f0f0f0' />
          </div>
        </div>
    );
}

export default Hero;