import "./css/Contact.css";

export default function Contact() {

    function submitForm(e: any) {
        e.preventDefault();

        showThankYou();
        
        setTimeout(() => {
            let subject = "Portfolio: New message from " + e.target.name.value;
            let from_name = e.target.name.value;
            
            e.target.subject.value = subject;
            e.target.from_name.value = from_name;

            e.target.submit();
        }, 2000);
    }

    function showThankYou() {
        let thankYou = document.querySelector(".thank-you");
        thankYou?.classList.remove("d-none");

        let form = document.querySelector(".contact-form");
        form?.classList.add("d-none");
    }

    return (
        <div className="contact">
            <form className="contact-form" action="https://api.web3forms.com/submit" method="POST" onSubmit={submitForm}>
                <input type="hidden" name="access_key" value="89044203-e5dd-4680-8c82-35376593cd8b" />
                <input type="hidden" name="subject" value="" />
                <input type="hidden" name="from_name" value="" />
                <input type="hidden" name="redirect" value = {window.location.href} />

                <div className = "form-group">
                    <input type="text" name="name" id="name" className = "form-control p-3" placeholder="Name" required />
                </div>
                <div className = "form-group mt-2">
                    <input type="text" name="email" id="email" className = "form-control p-3" placeholder="Email" required />
                </div>
                <div className = "form-group mt-2">
                    <textarea name="message" id="message" className = "form-control" placeholder="Message" rows={8} required></textarea>
                </div>
                <div className = "form-group d-flex justify-content-start mt-3">
                    <button type="submit" className = "btn-custom">Submit</button>
                </div>
            </form>
            <div className="thank-you d-none">
                <h2>Thank you for your message!</h2>
                <p>I will get back to you as soon as possible.</p>
            </div>
        </div>
    );
}