import React, { useEffect } from 'react';
import Hero from '../hero/Hero';
import Bio from '../body/Bio';
import Projects from '../project-components/Projects';
import data from '../data/projects.json';
import { ProjectsList } from '../types/ProjectsList';
import Contact from '../body/Contact';

interface HomeProps {
    data: ProjectsList;
}

export default function Home(props: HomeProps) {
    useEffect(() => {
        document.title = "Home | Portfolio";

    }, []);

    return (
        <div className="home">
            <div className = "header">
                <Hero />
            </div>
            <div className = "content p-2">
                <div className = "row pt-5 justify-content-center">
                    <div className = "col-lg-6 col-md-10">
                        <Bio />
                    </div>
                </div>
                <div className = "row mt-6 justify-content-center divider-header">
                    <h2>My Professional Projects</h2>
                </div>
                <div className = "row mt-4 justify-content-center">
                    <Projects title = "Professional Projects" projects = {props.data.professional}/>
                </div>
                <div className = "row mt-6 justify-content-center divider-header personal">
                    <h2>Things I've Done In My Free Time</h2>
                </div>
                <div className = "row mt-4 justify-content-center personal">
                    <Projects title = "Personal Projects" projects = {props.data.personal}/>
                </div>
                <div className = "row mt-6 justify-content-center contact-header">
                    <h3>Like what you see?</h3>
                    <h2>Give me a shout!</h2>
                </div>
                <div className = "row mt-4 justify-content-center">
                    <div className = "col-lg-4 col-md-10 p-4">
                        <Contact />
                    </div>
                </div>
            </div>
        </div>
    );
}