import React from 'react';
import Project from './Project';
import { ProjectsList } from '../types/ProjectsList';
import { ProjectType } from '../types/ProjectType';
import { useNavigate } from 'react-router-dom';

interface ProjectsProps {
    title: string;
    projects: ProjectType[];
}

export default function Projects(props: ProjectsProps) {
    const navigate = useNavigate();

    const navigateToProject = (projectTitle: string) => {
        navigate(`/projects/${projectTitle.toLowerCase().replace(/ /g, "-")}`);
    };

    return (
        <>
            {props.projects.map((project, index) => (
                <div className = "col-12 col-lg-3 mt-4" key = {index}>
                    <Project title = {project.title} description = {project.description} image = {project.image} technologies = {project.technologies} onClick = {navigateToProject}/>
                </div>
            ))}
        </>
    );
}