import { useParams } from 'react-router-dom';
import { ProjectsList } from '../types/ProjectsList';
import { ProjectType } from '../types/ProjectType';
import { useEffect } from 'react';

interface ProjectPageProps {
    projects: ProjectsList;
}

export default function ProjectPage(props: ProjectPageProps) {
    const projectTitle = useParams<{projectTitle: string}>();
    const project = props.projects.professional.concat(props.projects.personal).find((project: ProjectType) => project.title.toLowerCase().replace(/ /g, "-") === projectTitle.projectTitle);
    
    useEffect(() => {
        document.title = project ? project.title : "Project Not Found | Portfolio";

    }, []);

    if (!project) {
        return (
            <div className = "project">
                <div className = "row mt-5">
                    <div className = "col-12">
                        <h1>Project Not Found</h1>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className = "project-details d-flex align-items-center mt-5">
            <div className="row justify-content-center align-content-center p-lg-5 p-4">
                <div className="col-md-6 mb-3">
                    <div className="project-image">
                        {project.image !== "" && (
                            <img src={"../" + project.image} alt="project" className="project-image" />
                        ) || (
                            <div className="no-image">This project has no image</div>
                        )}
                    </div>
                </div>
                <div className="col-md-6 text-start project-text">
                    <h1 className = "mb-3">{project.title}</h1>
                    <h2 className = "mb-1"><strong>Description</strong></h2>
                    <p>{project.description}</p>
                    <h2 className = "mb-1"><strong>Technologies</strong></h2>
                    <p>{project.technologies.join(", ")}</p>
                </div>
            </div>
        </div>
    );
}