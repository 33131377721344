import React from "react";
import "./css/Project.css";
import { useNavigate } from "react-router-dom";

interface ProjectProps {
    title: string;
    description: string;
    image: string;
    technologies: string[];
    onClick: (id: string) => void;
}

export default function Project(props: ProjectProps) {
    const navigate = useNavigate();

    function truncate(input: string) {
        return input.length > 150 ? input.substring(0, 147) + "..." : input;
    }


    return (
        <div className="project-card" onClick={() => props.onClick(props.title)}>
            {props.image !== "" && (
                <div className="project-card-header">
                    <img src={props.image} alt="project" className="project-image" />
                </div>
            )}
            <div className="project-card-body ">
                <div className="project-card-title d-flex align-items-center justify-content-between">
                    <h4 className="m-0">{props.title}</h4>
                </div>
                <div className="project-card-description">
                    <p>{truncate(props.description)}</p>
                </div>
            </div>
        </div>
    );
}