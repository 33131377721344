export default function Footer() {
    return (
        <footer className = "bg-white text-center p-3">
            <div className = "row">
                <div className = "col-12 text-center">
                    <p className="m-0">&copy; 2024 - Anthony Marcellino - This website was made with love using React</p>
                </div>
            </div>
        </footer>
    );
}